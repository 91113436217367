import React, { useState } from 'react'
import { Routes, Route, BrowserRouter } from "react-router-dom";

import RequireAuth from './components/RequireAuth';

import Configurator from './Configurator';
import Home from './Home';
import AboutUs from './AboutUs';
import Login from './Login';
import Register from './Register';
import DashboardPersonalInformation from './components/dashboard/DashboardPersonalInformation';
import DashboardOrders from './components/dashboard/DashboardOrders';
import DashboardSavedAddresses from './components/dashboard/DashboardSavedAddresses';
import UpdatePersonal from './components/dashboard/UpdatePersonal';
import UpdateAddresses from './components/dashboard/UpdateAddresses';
import Cart from './components/cart/Cart';
import Header from './components/Header';
import Footer from './components/Footer';
import Whatsapp from './components/Whatsapp';
import Container from 'react-bootstrap/esm/Container';

const App = () => {
    const [cart, setCart] = useState([]);

    const addToCart = (item) => {
      setCart((prevItems) => [...prevItems, item]);
    };
    console.log(cart)
  return (
    <div className='bg-black'>
      <Container className='bg-black pb-5'>
        <Whatsapp/>
        <Header cart={cart}/>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/config" element={<Configurator addToCart={addToCart}/>} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/cart" element={<Cart cart={cart}/>} />
            <Route path='*' element={<Home/>}/>
            <Route element={<RequireAuth />}>
                <Route path="/dashboard/personal" element={<DashboardPersonalInformation />} />
                <Route path="/dashboard/personal/update" element={<UpdatePersonal />} />
                <Route path="/dashboard/addresses" element={<DashboardSavedAddresses />} />
                <Route path="/dashboard/addresses/update" element={<UpdateAddresses />} />
                <Route path="/dashboard/orders" element={<DashboardOrders />} />
            </Route>
        </Routes>
        <Footer/>
      </Container>
    </div>
  )
}

export default App