// import React, { useState } from "react";
// import axios from "axios";
// import { useNavigate, useLocation } from "react-router-dom";

// import Container from "react-bootstrap/esm/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Form from "react-bootstrap/Form";
// import Button from "react-bootstrap/esm/Button";

// import { createUser, updateUser } from "./service/firebase";





// const Register = () => {


//   const [status, setStatus] = useState(null);
//   const navigate = useNavigate();

//   const signUp = () => async () => {
//     try {
//       const firstName = document.getElementById("firstName").value;
//       const lastName = document.getElementById("lastName").value;
//       const number = document.getElementById("number").value;
//       const email = document.getElementById("email").value;
//       const password = document.getElementById("password").value;
//       const retypePassword = document.getElementById("retypePassword").value;
      
  
//       // Password validation
//       if (password.length < 5) {
//         setStatus("Password must be at least 5 characters long");
//         return;
//       }
//       if (password.length < 8) {
//         setStatus("Password must be at least 8 characters long for better security");
//         return;
//       }
//       if (password !== retypePassword) {
//         setStatus("Passwords do not match");
//         return;
//       }
  
//       await createUser(email, password).then((data)=>{
//         console.log(data)
//         updateUser(firstName +" "+ lastName, number)
//       }).finally(()=>{
//         console.log("1")

//         navigate("/dashboard", { replace: true });
//       });
//       console.log("User created successfully");
//       setStatus("User created successfully");
//       navigate("/login", { replace: true });
//     } catch (err) {
//       console.error("Error during sign up:", err);
//       setStatus(err.message || "An error occurred during sign up");
//     }
//   };





//   return (
//     <div className="bg-black pb-5">
//       <Container className="bg-black">
//         <Container
//           className="border mt-5 justify-content-center"
//           style={{ height: "100%" }}
//         >
//           <Row className="justify-content-center mt-5">
//             <Col className="h1 text-center text-light col-12">SIGN UP</Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 text-center text-secondary col-12">
//               <small>Welcome to Fusion Gaming!!</small>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 mt-5 text-light col-8 col-lg-4">
//               <Form.Group className="mb-3">
//                 <Form.Label>First Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   id="firstName"
//                   placeholder="xxxxxxxxxx"
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 mt-1 text-light col-8 col-lg-4">
//               <Form.Group className="mb-3">
//                 <Form.Label>Last Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   id="lastName"
//                   placeholder="xxxxxxxxxx"
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 mt-1 text-light col-8 col-lg-4">
//               <Form.Group className="mb-3">
//                 <Form.Label>Whatsapp Number</Form.Label>
//                 <Form.Control
//                   type="text"
//                   id="number"
//                   placeholder="xxxxxxxxxx"
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 mt-1 text-light col-8 col-lg-4">
//               <Form.Group className="mb-3">
//                 <Form.Label>Email</Form.Label>
//                 <Form.Control type="text" id="email" placeholder="xxxxxxxxxx" />
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 mt-1 text-light col-8 col-md-8 col-lg-2">
//               <Form.Group className="mb-3">
//                 <Form.Label>Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   id="password"
//                   placeholder="xxxxxxxx"
//                   aria-describedby="passwordHelpBlock"
//                 />
//               </Form.Group>
//             </Col>
//             <Col className="h5 mt-1 text-light col-8 col-md-8 col-lg-2">
//               <Form.Group className="mb-3">
//                 <Form.Label>Retype Password</Form.Label>
//                 <Form.Control
//                   type="password"
//                   id="retypePassword"
//                   placeholder="xxxxxxxx"
//                   aria-describedby="passwordHelpBlock"
//                 />
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 mt-1 text-light col-12 col-lg-4">
//               <Container className="h5 text-center text-secondary">
//                 <small>
//                   Your password must be 8-20 characters long, contain letters
//                   and numbers, and must not contain spaces, special characters,
//                   or emoji.
//                 </small>
//               </Container>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 mt-5 text-center text-light col-8 col-lg-4">
//               <Button variant="danger" onClick={signUp()} size="lg">
//                 <span className="h5">SIGN UP</span>
//               </Button>
//             </Col>
//           </Row>
//           <Row className="justify-content-center">
//             <Col className="h5 text-center text-dark col-12">
//               <small>{status === null ? "Click to create user" : status}</small>
//             </Col>
//           </Row>
//         </Container>
//       </Container>
//     </div>
//   );
// };

// export default Register;











import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { createUser, updateUser } from "./service/firebase";

const Register = () => {
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();

  const signUp = async () => {
    try {
      // Get input values
      const firstName = document.getElementById("firstName").value.trim();
      const lastName = document.getElementById("lastName").value.trim();
      const number = document.getElementById("number").value.trim();
      const email = document.getElementById("email").value.trim();
      const password = document.getElementById("password").value.trim();
      const retypePassword = document.getElementById("retypePassword").value.trim();

      // Input validation
      if (!firstName || !lastName) {
        setStatus("First and last names are required.");
        return;
      }

      // Validate WhatsApp number (only digits, 10-15 characters)
      if (!/^\d{10,15}$/.test(number)) {
        setStatus("Please enter a valid WhatsApp number (10-15 digits).");
        return;
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setStatus("Please enter a valid email address.");
        return;
      }

      // Validate password length and matching confirmation
      if (password.length < 8) {
        setStatus("Password must be at least 8 characters long.");
        return;
      }

      if (password !== retypePassword) {
        setStatus("Passwords do not match.");
        return;
      }

      // Simple password validation: only checks length (8-20 characters)
      const passwordRegex = /^.{8,20}$/;
      if (!passwordRegex.test(password)) {
        setStatus("Password must be 8-20 characters long.");
        return;
      }

      // Attempt to create the user
      await createUser(email, password)
        .then((data) => {
          console.log(data);
          updateUser(`${firstName} ${lastName}`, number);
        })
        .finally(() => {
          navigate("/dashboard", { replace: true });
        });

      setStatus("User created successfully. Please check your email to verify your account.");
      navigate("/login", { replace: true });
    } catch (err) {
      console.error("Error during sign up:", err);
      setStatus(err.message || "An error occurred during sign up");
    }
  };

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Container className="border mt-5 justify-content-center" style={{ height: "100%" }}>
          <Row className="justify-content-center mt-5">
            <Col className="h1 text-center text-light col-12">SIGN UP</Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-secondary col-12">
              <small>Welcome to Fusion Gaming!!</small>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-5 text-light col-8 col-lg-4">
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" id="firstName" placeholder="First Name" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-1 text-light col-8 col-lg-4">
              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" id="lastName" placeholder="Last Name" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-1 text-light col-8 col-lg-4">
              <Form.Group className="mb-3">
                <Form.Label>WhatsApp Number</Form.Label>
                <Form.Control type="text" id="number" placeholder="WhatsApp Number" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-1 text-light col-8 col-lg-4">
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" id="email" placeholder="Email" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-1 text-light col-8 col-md-8 col-lg-2">
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" id="password" placeholder="Password" />
              </Form.Group>
            </Col>
            <Col className="h5 mt-1 text-light col-8 col-md-8 col-lg-2">
              <Form.Group className="mb-3">
                <Form.Label>Retype Password</Form.Label>
                <Form.Control type="password" id="retypePassword" placeholder="Retype Password" />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-1 text-light col-12 col-lg-4">
              <Container className="h5 text-center text-secondary">
                <small>Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.</small>
              </Container>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 mt-5 text-center text-light col-8 col-lg-4">
              <Button variant="danger" onClick={signUp} size="lg">
                <span className="h5">SIGN UP</span>
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="h5 text-center text-danger col-12">
              <small>{status === null ? "Click to create user" : status}</small>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Register;
