import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyAm1rmUl_Wsu3IVpMuGE_Yqgf4pLKlynB4",
  authDomain: "fusion-gaming-home.firebaseapp.com",
  projectId: "fusion-gaming-home",
  storageBucket: "fusion-gaming-home.appspot.com",
  messagingSenderId: "972959854695",
  appId: "1:972959854695:web:e5cd826d0d893412bea4c2",
  measurementId: "G-7Q0GL7YTF1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

/**
 * Function to create a new user with email and password
 * @param {string} email - The email of the user
 * @param {string} password - The password of the user
 * @returns {Promise} - A promise that resolves with the user data or rejects with an error
 */
export function createUser(email, password) {
  return createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // User signed up successfully
      const user = userCredential.user;
      console.log("User created:", user);
      return user; // Return user object to use in other parts of the application
    })
    .catch((error) => {
      console.error("Error creating user:", error.code, error.message);
      throw error; // Throw the error to handle it in the calling function
    });
}

export function updateUser(name, phone) {
  updateProfile(auth.currentUser, {
    displayName: name,
  })
    .then(() => {
      // Profile updated!
      // ...
    })
    .catch((error) => {
      // An error occurred
      // ...
    });
}

/**
 * Function to sign in a user with email and password
 * @param {string} email - The email of the user
 * @param {string} password - The password of the user
 * @returns {Promise} - A promise that resolves with the user data or rejects with an error
 */
export function signIn(email, password) {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // User signed in successfully
      const user = userCredential.user;
      console.log("User signed in:", user);
      return user; // Return user object to use in other parts of the application
    })
    .catch((error) => {
      console.error("Error signing in:", error.code, error.message);
      throw error; // Throw the error to handle it in the calling function
    });
}
