import React, { useState, useEffect} from 'react'
import Header from '../Header'
import { useNavigate} from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import axios from '../../api/axios'

import Container from 'react-bootstrap/Container'
import Footer from '../Footer'
import Button from 'react-bootstrap/esm/Button'
import Table from 'react-bootstrap/Table';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
  

const DashboardSavedAddresses = () => {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [firstName, setFirstName] = useState('');

  useEffect(()=>{
    const user = sessionStorage.getItem('accessToken')? jwtDecode(sessionStorage.getItem('accessToken')).userInfo : false;
    setFirstName(user.firstName);
    async function getAddress(id){
      try{
        const response = await axios.post('/fetchaddresses',
          {id}, {withCredentials: true}
      );
      setAddresses(response.data);
      } catch (err) {
      console.log(err)
      }}
    getAddress(user.userId);
},[])

  const handleSubmit = (e) => {
    console.log(sessionStorage.getItem('accessToken'))
    sessionStorage.removeItem('accessToken');
    navigate('/', { replace: true });
  }
  
  return (
    <div className='bg-black pb-5'>
    <Container className='bg-black'>
      <Row style={{height:"55vh"}}>
        <Col className='col-lg-2 mt-5'>
          <Row className= 'p-4 text-light border-danger border rounded '>
            Hello {firstName} !
          </Row>
          <Row className= 'p-4 text-light mt-5 border-danger border rounded'>
            <Row className='justify-content-center mt-5'>
                <Col className='h5 px-1 text-danger col-12'>
                  <a className="link-light link-underline-opacity-0" href='/dashboard/personal'>Profile Info</a>
                </Col>
            </Row>
            <Row className='justify-content-center mt-1'>
                <Col className='h5 px-1 text-light col-12'>
                <a className="link-danger link-underline-opacity-0" href='/dashboard/addresses'>Saved Addresses</a>
                </Col>
            </Row>
            <Row className='justify-content-center mt-1'>
                <Col className='h5 px-1 text-light col-12'>
                <a className="link-light link-underline-opacity-0" href='/dashboard/orders'>Your Orders</a>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col className='h5 mt-5 px-4 text-light col-12'>
                    <Button variant="danger" onClick={handleSubmit} size="sm"><span className="h5">LOG OUT</span></Button>
                </Col>
            </Row>
          </Row>
        </Col>
        <Col className='col-lg-9 m-5 border border-light rounded'>
          <Row className='h3 m-3 mt-3 text-danger'>
            <Col className='lg-9'>
            Saved Addresses (<a className="link-secondary h5 link-underline-opacity-0" href='/dashboard/addresses/update'>Add/Update Addresses</a>)
            </Col>
          </Row>
          <Row className='h5 mt-5 px-3 text-light'>
            <Col>
              {(addresses[0])?
              <Table striped bordered hover variant='dark' >
                <thead>
                  <tr>
                    <th className='h5 text-danger text-center'>S.No.</th>
                    <th className='h5 text-danger text-center'>Address</th>
                  </tr>
                </thead>
                <tbody>
                {addresses.map((address,i)=>(
                  <tr>
                    <td className='small text-center'>{i+1}</td>
                    <td className='small'>{address.address}</td>
                  </tr>))}
                </tbody>
              </Table>: "No Addresses Saved!"}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
    </div>
  )
}

export default DashboardSavedAddresses