import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import { signIn } from './service/firebase';

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [firstName, setFirstName] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signIn(user, pwd); // Your login function
            setFirstName('User'); // Set firstName or isLoggedIn flag
            setErrMsg('');
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            console.error('Error:', err);
            setErrMsg('Error: Unable to login. Please try again.');
        }
    }

    return (
        <div className='bg-black'>
            <Container className='bg-black pb-5'>
                <Container className='border mt-5 p-5 justify-content-center' style={{height:"100%"}}>
                    <Row className='justify-content-center mt-5'>
                        <Col className='h1 text-center text-light col-12'>
                            SIGN IN
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col className='h5 text-center text-light col-10'>
                            <small>NEW TO FUSION GAMING?</small> 
                            <a className="link-danger link-underline-opacity-0" href='/register'>SIGN UP</a>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col className='h5 mt-5 text-light col-lg-4 col-10'>
                            <Form.Group className="mb-3" id="numberGroup">
                                <Form.Label htmlFor='number'>Email or Phone Number</Form.Label>
                                <Form.Control type="text" id='number' value={user} onChange={(e) => setUser(e.target.value)} placeholder="xxxxxxxxxx" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col className='h5 mt-1 text-light col-lg-4 col-10'>
                            <Form.Group className="mb-3" id="passwordGroup">
                                <Form.Label htmlFor='password'>Password</Form.Label>
                                <Form.Control type="password" id="password" value={pwd} onChange={(e) => setPwd(e.target.value)}  placeholder='xxxxxxxx' aria-describedby="passwordHelpBlock"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col className='h5 mt-5 text-center text-light col-12'>
                            <Button variant="danger" onClick={handleSubmit} size="lg"><span className="h5">LOGIN</span></Button>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col className='h5 text-center text-dark col-12'>
                            <small>
                                {firstName ? "Login is successful" : (errMsg ? errMsg : "Click to Login")}
                            </small>
                        </Col>
                    </Row>
                    <Row className='justify-content-end'>
                        <Col className='h5 mt-1 text-center text-light col-12'>
                            <a className="link-danger link-underline-opacity-0" href='/resetPassword'>Forgot Password</a>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Login;
