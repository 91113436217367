import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";

const Header = ({ cart }) => {
  const storedValue = () => {

    
    sessionStorage.getItem("accessToken");
    const firstName = sessionStorage.getItem("accessToken")
      ? jwtDecode(sessionStorage.getItem("accessToken")).userInfo?.firstName
      : false;
    return firstName;
  };

  return (
    <>
      <Navbar
        className="bg-black border-bottom border-secondary"
        data-bs-theme="dark"
      >
        <Container className="bg-black" style={{ height: "15px" }}>
          <Nav className="justify-content-center">
            <Navbar.Brand
              className="m-auto text-danger"
              href="tel:+91-6369933507"
            >
              <small>+91-63699 33507</small>
            </Navbar.Brand>
          </Nav>
          <Nav className="ms-auto justify-content-center">
            <Navbar.Brand className="text-light" href="/dashboard/personal">
              {storedValue() ? (
                <span className="text-danger">{storedValue()}</span>
              ) : (
                <small>Login</small>
              )}
            </Navbar.Brand>
            <Navbar.Brand className="text-light">
              <small>|</small>
            </Navbar.Brand>
            <Navbar.Brand className="text-light">
              {storedValue() ? (
                <Link
                  to="/cart"
                  className="link-danger link-underline-opacity-0"
                >
                  Cart
                </Link>
              ) : (
                <Link
                  to="/cart"
                  className="link-light link-underline-opacity-0"
                >
                  Cart
                </Link>
              )}
            </Navbar.Brand>
          </Nav>
        </Container>
      </Navbar>
      <Navbar
        expand="lg"
        className="bg-black sticky-top border-light border-bottom bg-opacity-75"
        data-bs-theme="dark"
      >
        <Container className="bg-black">
          <a class="navbar-brand">
            <Link to="/">
              <img
                src="/FG_Logo.ico"
                className="d-block w-10"
                width={95}
                height={90}
                alt="1"
              />
            </Link>
          </a>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="ms-auto">
              <Navbar.Brand className="h2 p-1">
                <Link
                  to="/config"
                  className="link-light link-underline-opacity-0"
                >
                  Build Your Own PC
                </Link>
              </Navbar.Brand>
              <Navbar.Brand className="h2 p-1" href="#home">
                Pre-Builts
              </Navbar.Brand>
              <Navbar.Brand className="h2 p-1" href="/aboutus">
                About Us
              </Navbar.Brand>
              <Navbar.Brand className="h2 p-1" href="#home">
                Support
              </Navbar.Brand>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
