import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";

import Header from "../Header";
import Footer from "../Footer";

const UpdatePersonal = () => {
  const url = "https://fusion-backend.onrender.com";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [userId, setUserId] = useState("");

  const [status, setStatus] = useState(null);

  const updateUser = () => async (req, res) => {
    try {
      const res = await axios.post(
        url + "/updateuser",
        { userId, firstName, lastName, number, email, gender, dob },
        { withCredentials: true }
      );
      sessionStorage.setItem("accessToken", res?.data);
      setStatus("Updated!");
    } catch (err) {
      setStatus(err.request.response);
      console.log(err.request.response);
    }
  };

  useEffect(() => {
    function getUser() {
      sessionStorage.getItem("accessToken");
      const user = jwtDecode(sessionStorage.getItem("accessToken")).userInfo;
      console.log(user);
      setUserId(user.userId);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setNumber(user.number);
      setEmail(user.email);
      setDob(user.dob);
      setGender(user.gender);
    }
    getUser();
  }, []);

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row className="justify-content-center mt-5">
          <Col className="h1 text-center text-light col-12">
            Update Personal Information
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 text-center text-secondary col-12">
            <small>Secure Data Entry</small>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-5 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                id="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                id="lastName"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Whatsapp Number</Form.Label>
              <Form.Control
                type="text"
                id="number"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control
                type="date"
                id="dob"
                onChange={(e) => setDob(e.target.value)}
                value={dob}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Gender</Form.Label>
              <Form.Select
                aria-label="Select"
                id="gender"
                onChange={(e) => setGender(e.target.value)}
                value={gender}
              >
                <option value="0" hidden>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-5 text-center text-light col-8 col-lg-4">
            <Button variant="danger" onClick={updateUser()} size="lg">
              <span className="h5">Update</span>
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 text-center text-dark col-12">
            <small>{status === null ? "Click to update user" : status}</small>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdatePersonal;
