import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { useNavigate} from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import axios from '../../api/axios'

import Container from 'react-bootstrap/Container'
import Footer from '../Footer'
import Button from 'react-bootstrap/esm/Button'


import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const DashboardPersonalInformation = () => {

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    sessionStorage.removeItem('accessToken');
    navigate("/", { replace: true });
  }

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');

  useEffect(()=>{
      const userId = sessionStorage.getItem('accessToken')? jwtDecode(sessionStorage.getItem('accessToken')).userInfo?.userId : false;
      async function getUser(userId){
        try{
          const response = await axios.post('/fetchuser',
            {userId}, {withCredentials: true}
        );
        sessionStorage.setItem('accessToken', response?.data);
        const user = jwtDecode(sessionStorage.getItem('accessToken')).userInfo;
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setNumber(user.number);
        setEmail(user.email);
        setDob(user.dob);
        setGender(user.gender);
        } catch (err) {
        console.log(err)
        }}
      getUser(userId);
  },[])

  return (
    <div className='bg-black pb-5'>
    <Container className='bg-black'>
      <Row style={{height:"55vh"}}>
        <Col className='col-lg-2 mt-5'>
          <Row className= 'p-4 text-light border-danger border rounded '>
            Hello {firstName} !
          </Row>
          <Row className= 'p-4 text-light mt-5 border-danger border rounded'>
            <Row className='justify-content-center mt-5'>
                <Col className='h5 px-1 text-danger col-12'>
                  <a className="link-danger link-underline-opacity-0" href='/dashboard/personal'>Profile Info</a>
                </Col>
            </Row>
            <Row className='justify-content-center mt-1'>
                <Col className='h5 px-1 text-light col-12'>
                <a className="link-light link-underline-opacity-0" href='/dashboard/addresses'>Saved Addresses</a>
                </Col>
            </Row>
            <Row className='justify-content-center mt-1'>
                <Col className='h5 px-1 text-light col-12'>
                <a className="link-light link-underline-opacity-0" href='/dashboard/orders'>Your Orders</a>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col className='h5 mt-5 px-4 text-light col-12'>
                    <Button variant="danger" onClick={handleSubmit} size="sm"><span className="h5">LOG OUT</span></Button>
                </Col>
            </Row>
          </Row>
        </Col>
      <Col className='col-lg-9 mt-5 ms-5 border border-light rounded'>
        <Row className='h3 mt-3 text-danger'>
          <Col className='m-3'>
            Person Information (<a className="link-secondary h5 link-underline-opacity-0" href='/dashboard/personal/update'>Update</a>)
          </Col>
        </Row>
        <Row className='h5 mt-5 px-3 text-light'>
          <Col>
            Firstname 
          </Col>
          <Col>
            Lastname 
          </Col>
        </Row>
        <Row className='h5 mt-1 px-3 text-danger'>
          <Col>
            {firstName} 
          </Col>
          <Col>
            {lastName} 
          </Col>
        </Row>
        <Row className='h5 mt-4 px-3 text-light'>
          <Col>
            Date Of Birth <span className='small text-secondary'>(YYYY-MM-DD)</span>
          </Col>
          <Col>
            Gender
          </Col>
        </Row>
        <Row className='h5 mt-1 px-3 text-danger'>
          <Col>
            {dob}
          </Col>
          <Col>
            {gender}
          </Col>
        </Row>
        <Row className='h5 mt-4 px-3 text-light'>
          <Col>
            Contact Number
          </Col>
          <Col>
            Email ID
          </Col>
        </Row>
        <Row className='h5 mt-1 mb-5 px-3 text-danger'>
          <Col>
            {number}
          </Col>
          <Col>
            {email}
          </Col>
        </Row>
      </Col>
      </Row>
    </Container>
    </div>
  )
}

export default DashboardPersonalInformation