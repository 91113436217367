import { useLocation, Outlet, Navigate } from "react-router-dom";

const RequireAuth = () => {
    const {location} = useLocation();

    return (
        sessionStorage.getItem('accessToken') ? <Outlet/>: <Navigate to='/login' state={{from: location}} replace/> 
    )
}

export default RequireAuth;