import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/esm/Button";

import Header from "../Header";
import Footer from "../Footer";

const UpdateAddresses = () => {
  const url = "https://fusion-backend.onrender.com";
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [userId, setUserId] = useState("");

  const [status, setStatus] = useState("");

  useEffect(() => {
    const user = jwtDecode(sessionStorage.getItem("accessToken")).userInfo;
    async function getAddresses(id) {
      try {
        console.log("calling..");
        setUserId(id);
        const res = await axios.post(
          url + "/fetchaddresses",
          { id },
          { withCredentials: true }
        );
        setAddresses(res.data);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    }
    getAddresses(user.userId);
  }, []);

  async function addAddress() {
    try {
      const res = await axios.post(
        url + "/addaddress",
        { address },
        { withCredentials: true }
      );
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function updateAddress() {
    try {
      const res = await axios.post(
        url + "/updateaddress",
        { newAddress },
        { withCredentials: true }
      );
      setStatus(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function delAddress(addressid, e) {
    try {
      const res = await axios.post(
        url + "/deladdress",
        { addressid },
        { withCredentials: true }
      );
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row className="justify-content-center mt-5">
          <Col className="h1 text-center text-light col-12">
            Update Addresses
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 text-center text-secondary col-12">
            <small>Secure Data Entry</small>
          </Col>
        </Row>
        <Col>
          {addresses[0] ? (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr key={1}>
                  <th className="h5 text-danger text-center">S.No.</th>
                  <th className="h5 text-danger text-center">Address</th>
                  <th className="h5 text-danger text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {addresses.map((a, i) => (
                  <tr key={i + 2}>
                    <td className="small text-center">{i + 1}</td>
                    <td>
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          id={a.addressid}
                          onChange={(e) =>
                            setNewAddress({
                              userid: a.userid,
                              addressid: a.addressid,
                              address: e.target.value,
                            })
                          }
                          placeholder={a.address}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <a
                        className="link-danger link-underline-opacity-0"
                        onClick={updateAddress}
                        href=""
                        size="sm"
                      >
                        <span className="h5">Update </span>
                      </a>
                      <a
                        className="link-danger link-underline-opacity-0"
                        onClick={(e) => delAddress(a.addressid, e)}
                        href=""
                        size="sm"
                      >
                        <span className="h5"> Delete</span>
                      </a>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="small text-center">{addresses.length + 1}</td>
                  <td>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        id="address"
                        onChange={(e) =>
                          setAddress({
                            userid: userId,
                            address: e.target.value,
                          })
                        }
                        placeholder="Enter New Address"
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <a
                      className="link-danger link-underline-opacity-0"
                      onClick={addAddress}
                      href=""
                      size="sm"
                    >
                      <span className="h5">Add</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <Table striped bordered hover variant="dark">
              <thead>
                <tr key={1}>
                  <th className="h5 text-danger text-center">S.No.</th>
                  <th className="h5 text-danger text-center">Address</th>
                  <th className="h5 text-danger text-center">Actions</th>
                </tr>
                <tr>
                  <td className="small text-center">{addresses.length + 1}</td>
                  <td>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        id="address"
                        onChange={(e) =>
                          setAddress({
                            userid: userId,
                            address: e.target.value,
                          })
                        }
                        placeholder="Enter New Address"
                      />
                    </Form.Group>
                  </td>
                  <td>
                    <a
                      className="link-danger link-underline-opacity-0"
                      onClick={addAddress}
                      href=""
                      size="sm"
                    >
                      <span className="h5">Add</span>
                    </a>
                  </td>
                </tr>
              </thead>
            </Table>
          )}
        </Col>
        <Row className="justify-content-center">
          <Col className="h5 text-center text-dark col-12">
            <small>{status}</small>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdateAddresses;
